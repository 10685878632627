<template>
    <div class="account-content">

        <template v-if="isContentLoading">
            <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
                <div class="section-title margin-deliver text-capitalize">{{ $t("subscription packages") }}</div>
            </div>
            <div class="row row-cols-md-3 has-col-gap">
                <subscription-loader v-for="index in 3" :key="index"></subscription-loader>
            </div>
        </template>

        <template v-for="pGroup in packages" v-else>
            <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-center">
                <div class="section-title margin-deliver text-capitalize">{{ $t(pGroup.title) }}</div>
                <div class="sub_package_switcher">
                    <div class="sub_package_switch" v-if= "pGroup.type ==1"  v-for="(plan, index) in plans"
                         :class="index==selectedPlan?`active`: ``" @click="selectPlan(index)"> {{ plan }}
                    </div>
                </div>
                <div class="d-flex align-items-center"  v-if="showCancelButton(pGroup) && !user.subscription_cancel_request">
                    <v-popover offset="10" :disabled="false" >
                        <button class="ej__tooltip__button mr-2"><i class="eicon e-question"></i></button>
                        <template slot="popover">
                            <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                            <a v-close-popover href="https://easy.jobs/docs/cancel-your-subscription/#0-toc-title"
                               target="_blank"  v-if="showCancelButton(pGroup)">
                                {{ $t(`How to cancel subscription?`) }}
                            </a>
                        </template>
                    </v-popover>
                    <div class="dropdown dropdown__package dropdown-more">
                        <button data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" class="button light-button dropdown-toggle">
                            <i class="eicon e-setting"></i></button>
                        <ul class="dropdown-menu dropdown-menu-right">
                            <template v-if="!user.subscription_cancel_request">

                                <li><a href="javascript:void(0)"  @click="resumeSubscription" v-if="!isExpired && canResumeSubscription"><i class="eicon e-restart"></i> {{$t(`Resume Subscription`)}}</a></li>
                                <li><a href="javascript:void(0)"  @click="pauseSubscription" v-if="!isExpired && !canResumeSubscription" ><i class="eicon e-pause"></i> {{$t(`Pause Subscription`)}}</a></li>
                                <li><a href="javascript:void(0)" @click="cancelSubscription"><i class="eicon e-cancel" ></i>{{$t(`Cancel Subscription`)}}</a></li>
                            </template>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="row row-cols-md-3 has-col-gap mb-1">
                <div class="col" v-for="pack in pGroup.packages">
                    <div class="subscription-package" :class="{'active' : pack.is_selected}">
                        <div class="package-title">{{ $t(pack.name) }}</div>
                        <div class="package-pricing-box">
                            <span class="previous-price" v-if="pack.has_discount">${{ pack.price }}</span>
                            <span class="package-price">${{ pack.discounted_price }}</span>
                            <span class="package-duration">{{ $t(pack.plan) }}</span>
                        </div>
                        <ul class="plan-states">
                            <li v-for="attr in pack.attrs" :class="{ 'disable' : !attr.active }">{{
                                    $t(attr.title)
                                }}
                            </li>
                        </ul>
                        <p></p>
                         <template>
                            <a href="javascript:void(0)" v-if="pack.is_selected && !pack.isDowngrade" class="button">{{ $t("Selected") }}</a>

                            <a href="javascript:void(0)"  v-if="!pack.is_selected && !pack.isDowngrade" @click.prevent="upgradePackage(pack)" class="button">{{ $t("Upgrade") }}</a>

                            <a href="javascript:void(0)"  v-if="!pack.is_selected && pack.isDowngrade" @click.prevent="upgradePackage(pack)" class="button info-button" style="background: #2fc1e1 !important;">{{ $t("Downgrade") }}</a>
                        </template>

                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import client from "../../app/api/Client";
import {mapState, mapActions} from 'vuex';
import {UPDATE_USER_DATA} from "../../constants/action-type";
const SubscriptionLoader = () => import("../../components/_loaders/SubscriptionLoader.vue");
const APPSUMO_PACKAGE = 50;
const TEST_PACKAGE = 4;
const LIFETIME = 3;

export default {
    components: {
        SubscriptionLoader
    },
    data() {
        return {
            isContentLoading: false,
            packagePlans: [],
            packages : [],
            plans: {
                'Month': 'Monthly',
                'Year': 'Yearly',
                "Lifetime": "Lifetime"
            },
            selectedPlan: 'Year',
            canResumeSubscription: true
        }
    },
    computed: {
        ...mapState(['user', 'token', 'isExpired']),
    },
    methods: {
        ...mapActions([UPDATE_USER_DATA]),
        selectPlan(index) {
            let plans = JSON.parse(JSON.stringify(this.packagePlans));
            this.selectedPlan = index?? 'Month';
            this.packages = plans.map(p => {
                let  np = p;
                if(np.type ==1) {
                    np.packages = p.packages.filter(pk => {
                        return  pk.plan === this.selectedPlan
                    });
                }
                return np;
            });
        },
        async getPackages() {
            this.isContentLoading = true;
            try {
                let {data} = await client().get('/subscription/packages');
                this.packagePlans = data.data;
                let plans = JSON.parse(JSON.stringify(this.packagePlans));
                plans.map(p => {
                        let  np = p;
                        np.packages = p.packages.filter(pk => {
                           if(pk.is_selected == true) {
                               this.selectedPlan = pk.plan
                           }
                        });
                        return np;
                    });

            } catch (e) {
            }
            this.isContentLoading = false;
        },
        async cancelSubscription() {
            let message = {
                title: this.$t('confirmation'),
                body: this.$t('Are you sure, you want to cancel subscription?'),
            };

            let isConfirm = await this.$dialog.confirm(message);

            if (isConfirm !== false) {
                try {
                    let {data: {data, message}} = await client().put('/subscription/cancel');
                    this[UPDATE_USER_DATA](data);
                    // await this.getPackages();
                    this.$toast.success(message);
                } catch (e) {
                }
            }
        },
        async pauseSubscription() {
            let message = {
                title: this.$t('confirmation'),
                body: this.$t('Are you sure, you want to pause subscription?'),
            };

            let isConfirm = await this.$dialog.confirm(message);

            if (isConfirm !== false) {
                try {
                    let {data: {data, message}} = await client().put('/subscription/pause');
                    this.$toast.success(message);
                    this.canResumeSubscription = true;
                } catch (e) {
                }
            }

        },
        async resumeSubscription() {
            let message = {
                title: this.$t('confirmation'),
                body: this.$t('Are you sure, you want to resume subscription?'),
            };

            let isConfirm = await this.$dialog.confirm(message);

            if (isConfirm !== false) {
                try {
                    let {data: {data, message}} = await client().put('/subscription/resume');
                    this.$toast.success(message);
                    this.canResumeSubscription = false;
                } catch (e) {
                }
            }

        },
        showCancelButton(pGroup) {
            let pack = this.user.package_rules;

            return !(
                pGroup.type === TEST_PACKAGE ||
                pack.name === 'Free' ||
                pGroup.type === APPSUMO_PACKAGE ||
                pack.type === APPSUMO_PACKAGE ||
                pack.plan === LIFETIME
            );
        },

        upgradePackage(pack) {
            if (pack.type === APPSUMO_PACKAGE) {
                window.location.href = `/appsumo?token=${this.token}`;
                return;
            }
            if (pack.upgradable) {
                this.$router.push({name: 'checkout', params: {slug: pack.slug}});
            } else {
                pack.messages.forEach(msg => {
                    if(!_.isNull(msg)) {
                        this.$toast.error(this.$t(msg));
                    }
                });
                this.$toast.error(this.$t('Subscription package downgrade is not possible. Please contact with support.'));

            }
        }
    },
    async mounted() {
        await this.getPackages();
        await this.selectPlan(this.selectedPlan);
        this.canResumeSubscription = this.user.is_subscription_pause ===1;
    }
}
</script>

<style scoped>
.page-body .content-area__body .account-section .account-content .subscription-package .package-pricing-box {
    width: auto !important;
}

.sub_package_switcher .sub_package_switch {
    padding: 10px 10px;
}
.sub_package_switcher .sub_package_switch.active {
    background: #587dfc;
    color: #fff;
}

.sub_package_switcher .sub_package_switch:first-child {
    border-radius: 4px 0 0 4px;
}
.sub_package_switcher {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.sub_package_switcher .sub_package_switch {
    font-size: .9rem;
    font-weight: 400;
    border: 1px solid #587dfc;
    cursor: pointer;
}

.dropdown-more .dropdown-menu {
    padding: 0;
    border: none;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(125, 128, 145, 0.3);
    /*top: 30px !important;*/
    transform: translate(0) !important;
    top: 100% !important;
    min-width: 180px;
    left: auto !important;
    right: 0;
}
.dropdown-more .dropdown-menu {
    padding: 7px;
}
.dropdown-more .dropdown-menu li a {
    display: flex;
    padding: 7px;
    align-items: center;
    line-height: 1;
    font-size: 12px;
}
.dropdown-more .dropdown-menu li a i {
    margin-right: 5px;
}
.dropdown-more .dropdown-menu li:hover {
    background: #f5f7fd;
}
@media (max-width: 767) {
    .dropdown-more .dropdown-menu {
        min-width: 120px;
        top: 15px !important;
    }
    .dropdown-more .dropdown-menu {
        padding: 4px;
    }
    .dropdown-more .dropdown-menu li a {
        padding: 4px;
    }
    .dropdown-more .dropdown-menu li a .language-flag {
        width: 25px;
        margin-right: 4px;
        padding-right: 6px;
    }
    .dropdown-more .dropdown-menu li:hover {
        background: #f5f7fd;
    }
}

.dropdown__package .button  {
    color: #597dfc;
    background: #e6ebff !important;
    font-size: 18px;
}
.dropdown__package .button:hover  {
    background: #e0e6f9 !important;
}
.dropdown__package .button:after  {
    content: '';
    display: none;
}
.subscription-package .plan-states {
    list-style: none;
}

</style>